export default {
  path: '/',
  component: () => import(/* webpackChunkName: "print" */ '@/layouts/print-layout.vue'),
  children: [
    {
      path: 'record-of-transfer',
      children: [
        {
          path: ':id/print',
          name: 'project-rot-print',
          meta: { title: 'Cetak Berita Acara' },
          component: () =>
            import(
              /* webpackChunkName: "print" */ '@/views/print/record-of-transfer.vue'
            ),
        },
      ],
    },
  ],
}

import '@unocss/reset/tailwind.css'
import { createPinia } from 'pinia'
import 'virtual:uno.css'
import { createApp } from 'vue'
import '@/assets/global.css'
import sentry from '@/services/sentry'
import App from './App.vue'
import router from './router'

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(sentry, router)

app.mount('#app')
